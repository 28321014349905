import apiTool from '@/command/apiTool'

function onOpen({ update, form = {} } = {}) {
  apiTool.showDrawer({
    title: '预定须知',
    formData: { ...form },
    width: '600px',
    form: [
      {
        name: '标题',
        key: 'title',
        type: 'input'
      },
      {
        name: '内容',
        key: 'content',
        type: 'textArea'
      }
    ],
    success: ({ data, setHidden }) => {
      update(data).then(setHidden)
    },
    foot: ({ close, submit }) => {
      return [
        {
          name: '返回',
          onClick: close
        },
        {
          name: '确认',
          type: 'primary',
          onClick: submit
        }
      ]
    }
  })
}

function delData({ del, form = {} } = {}) {
  del(form)
}

function createForm({ create }) {
  apiTool.showDrawer({
    title: '预定须知',
    formData: {},
    width: '600px',
    form: [
      {
        name: '标题',
        key: 'title',
        type: 'input'
      },
      {
        name: '内容',
        key: 'content',
        type: 'textArea'
      }
    ],
    success: ({ data, setHidden }) => {
      create(data).then(setHidden)
    },
    foot: ({ close, submit }) => {
      return [
        {
          name: '返回',
          onClick: close
        },
        {
          name: '确认',
          type: 'primary',
          onClick: submit
        }
      ]
    }
  })
}

export default ({ update, del, create }) => {
  return {
    url: '/config/farmReserveNotice/list',
    edit: '/config/farmReserveNotice/update',
    del: '/config/farmReserveNotice/delete/',
    create: '/config/farmReserveNotice/add',
    type: 'table',
    props: {
      showFootButton: true,
      on: {
        handleAddData: () => createForm({ create })
      },
      columns: [
        {
          dataIndex: 'title',
          align: 'left'
        },
        {
          dataIndex: 'a1',
          tableType: 'editDelete',
          width: '200px',
          onEdit: (text, records) => onOpen({ update, form: records }),
          onDel: (text, records) => delData({ del, form: records })
        }
      ]
    }
  }
}
